import axios from 'axios'
const url = process.env.REACT_APP_OPW_SERVICE_API_ENDPOINT
interface ForgotPasswordResponse {
  status: 'success' | 'fail'
  message?: string
}

export const forgotPassword = async (email: string): Promise<ForgotPasswordResponse> => {
  try {
    const response = await axios.post<ForgotPasswordResponse>(`${url}/auth/forgotPassword`, {
      email,
    })

    return response.data
  } catch (error) {
    console.error('Error occurred while fetching forgot password:', error)
    throw error
  }
}
