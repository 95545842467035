import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import FullScreenLoader from '../Components/FullScreenLoader/FullScreenLoader'
import { getMe } from '../services/opw.api'
import { IAuthContext } from '../types'

const AuthContext = React.createContext<IAuthContext>({
  data: {
    token: '',
    user: null,
  },
  updateData: () => {},
})

const AuthProvider = (props: { children: JSX.Element[] | JSX.Element | null }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const isPubUrl =
    location.pathname.slice(0, 7) === '/serviceorder/' && location.pathname.length > 7

  const [data, setData] = useState(() => {
    const localStorageData = localStorage.getItem('data')
    if (localStorageData) return JSON.parse(localStorageData)
    else return null
  })

  const [checked, setChecked] = useState(false)

  const updateData = (key: string, value: any) => {
    setData((prevData: any) => ({
      ...prevData,
      [key]: value,
    }))
  }

  const getUserData = async () => {
    if (!data?.token) {
      navigate('/login')
      setChecked(true)
      return
    }
    try {
      const request = await getMe(data?.token)
      if (request?.data?.user) {
        updateData('user', request.data.user)
      }
      setChecked(true)
    } catch (err) {
      console.error(err)
      setChecked(true)
      setData({
        token: '',
        user: null,
      })
      localStorage.setItem(
        'data',
        JSON.stringify({
          token: '',
          user: null,
        }),
      )
      if (location.pathname !== '/login' && location.pathname !== '/login/') navigate('/login')
    }
  }
  useEffect(() => {
    localStorage.setItem('data', JSON.stringify(data))
  }, [data])

  useEffect(() => {
    getUserData()
  }, [])

  if (location.pathname !== '/login' && !checked && !isPubUrl) {
    return <FullScreenLoader />
  }

  if (isPubUrl)
    return (
      <AuthContext.Provider value={{ data, updateData }}>{props.children}</AuthContext.Provider>
    )

  if (location.pathname !== '/login' && location.pathname !== '/login/' && !data?.token) {
    return <Navigate to={'/login'} replace />
  }

  return <AuthContext.Provider value={{ data, updateData }}>{props.children}</AuthContext.Provider>
}

export { AuthProvider }

export default AuthContext
