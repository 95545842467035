import React, { ReactNode, useContext } from 'react'
import LoginHeader from './LoginHeader'
import LoginSideBar from './LoginSideBar'
import './LayoutStyles.css'
import AuthContext from '../../contexts/AuthContext'

interface Props {
  children: ReactNode
}

export const Layout = ({ children }: Props) => {
  const authContext = useContext(AuthContext)
  const isLoggedIn = authContext?.data?.token && authContext?.data?.user ? true : false
  return (
    <div className='h-100 d-flex flex-column'>
      {isLoggedIn && <LoginHeader />}
      <div className='flex-grow-1 d-flex overflow-hidden'>
        {isLoggedIn && (
          <div
            className='d-none d-lg-inline-block h-100 hidden-scrollbar'
            style={{ overflowY: 'scroll', minWidth: '310px' }}
          >
            <LoginSideBar />
          </div>
        )}
        <main
          className='flex-grow-1 h-100 px-2 px-sm-3 px-lg-5'
          style={{ backgroundColor: '#EFF4F5', overflowY: 'scroll' }}
        >
          {children}
        </main>
      </div>
    </div>
  )
}
