import { default as React, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { default as dashboardIcon } from '../../Assets/Icons/Dashboard-Icons/house-insurance.png'
import { default as paymentsIcon } from '../../Assets/Icons/Dashboard-Icons/payments-icon.png'
import { default as techSupportIcon } from '../../Assets/Icons/Dashboard-Icons/technical-support.png'
import { default as userManagementIcon } from '../../Assets/Icons/Dashboard-Icons/user-management.png'
import { default as claimsIcon } from '../../Assets/Icons/Dashboard-Icons/warranty-claims.png'
import { default as marketingIcon } from '../../Assets/Icons/Dashboard-Icons/warranty-marketing.png'
import { default as reportingIcon } from '../../Assets/Icons/Dashboard-Icons/warranty-reporting.png'
import { default as salesIcon } from '../../Assets/Icons/Dashboard-Icons/warranty-sales.png'
import { default as trainingIcon } from '../../Assets/Icons/Dashboard-Icons/warranty-training.png'

interface MenuItem {
  name: string
  link: string
  icon: string
  active: boolean
}

interface MenuItem {
  name: string
  link: string
  icon: string
  active: boolean
}

export default function LoginSideBar() {
  const location = useLocation()
  const MenuIems: MenuItem[] = useMemo(() => {
    return [
      {
        name: 'Dashboard',
        link: '/',
        icon: dashboardIcon,
        active: !location.pathname.trim().split('/')[1],
      },
      {
        name: 'Warranty sales',
        link: '/sales',
        icon: salesIcon,
        active: location.pathname.includes('sales'),
      },
      {
        name: 'payments, chargebacks & credits',
        link: '/payment',
        icon: paymentsIcon,
        active: location.pathname.includes('payment'),
      },
      {
        name: 'Service Order',
        link: '/serviceorder',
        icon: claimsIcon,
        active: location.pathname.includes('serviceorder'),
      },
      {
        name: 'Warranty marketing support',
        link: '/marketing',
        icon: marketingIcon,
        active: location.pathname.includes('marketing'),
      },
      {
        name: 'Warranty training support',
        link: '/training',
        icon: trainingIcon,
        active: location.pathname.includes('training'),
      },
      {
        name: 'Warranty reporting',
        link: '/report',
        icon: reportingIcon,
        active: location.pathname.includes('report'),
      },
      {
        name: 'user management',
        link: '/profile',
        icon: userManagementIcon,
        active: location.pathname.includes('profile'),
      },
      {
        name: 'technical support',
        link: '/support',
        icon: techSupportIcon,
        active: location.pathname.includes('support'),
      },
    ]
  }, [location.pathname])
  return (
    <nav
      className='d-block px-2 py-4 h-100 overflow-visible bg-white'
      data-bs-theme='white'
      style={{ minHeight: '417px' }}
    >
      {MenuIems.map((item) => {
        return (
          <Link
            key={item.name.trim()}
            to={item.link}
            className={`side-bar-link p-2 rounded-pill text-decoration-none d-flex align-items-center justify-content-start flex-nowrap ${item.active ? 'active' : 'text-secondary'}`}
          >
            <img src={item.icon} alt={item.name} height={25} />
            <span className='text-start no-wrap ms-3 text-capitalize'>{item.name}</span>
          </Link>
        )
      })}
    </nav>
  )
}
