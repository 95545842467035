import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import NotFoundImg from '../../Assets/404/NotFound.png'

const NotFound = () => {
  return (
    <React.Fragment>
      <Row>
        <Col className='text-center' xs={12}>
          <Image className='mt-5 fluid' src={NotFoundImg} fluid rounded />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default NotFound
